html,
body,
#root {
  height: 100%;
  max-width: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a.disabled,
span.disabled {
  /* Make the disabled links grayish*/
  color: gray;
  opacity: 0.7;
  /* And disable the pointer events */
  pointer-events: none;
}

.grow-height-animate {
  height: 0;
  transition: 0.5s;
}

.grow-height-animate.show {
  height: 50%;
}

.slide-left-animate {
  right: -40em;
  transition: 0.5s;
  min-width: 20em;
  max-width: 100%;
}

.slide-left-animate.show {
  right: 0;
}

.sidebar {
}

@media only screen and (min-width: 768px) {
  .sidebar {
    flex-direction: column;
    height: 100%;
    max-width: 25%;
    box-shadow: 0 16px 38px -12px rgba(0;0;0;.56), 0 4px 25px 0 rgba(0;0;0;.12),
      0 8px 10px -5px rgba(0;0;0;.2);
  }
}
